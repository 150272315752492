import * as React from 'react'
import { MetaTags } from 'lib/seo'
import { Badge, classNames, Logo } from '../shared-ui'
import Link from 'next/link'
import { ChevronRightIcon } from '@heroicons/react/solid'

export const AuthLayout: React.FC<{ id: string }> = ({ children, id }) => {
    return (
        <div className="h-full min-h-screen bg-white sm:bg-gray-50" id={id}>
            <MetaTags title="Sign In" />
            <div className="min-h-full flex flex-col justify-center sm:py-12 pt-6 pb-36 sm:px-6 lg:px-8">
                {children}
            </div>
        </div>
    )
}

export const AuthHeader: React.FC<{
    title: string
    step?: string
    alternative?: string
    alternativePath?: string
}> = ({ title, alternative, alternativePath, step }) => {
    return (
        <div className="sm:mx-auto sm:w-full items-center flex flex-col">
            <MetaTags title={title} />
            <Logo size="md" />
            <h2 className="mt-3 sm:mt-6 text-center text-xl sm:text-2xl font-bold text-gray-950">
                {title}
            </h2>
            {step && (
                <Badge color="yellow" className="mt-2">
                    {step}
                </Badge>
            )}
            {alternative && alternativePath ? (
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or{' '}
                    <Link href={alternativePath} passHref>
                        <a className="font-medium text-indigo-600 hover:text-indigo-500">
                            {alternative}
                        </a>
                    </Link>
                </p>
            ) : null}
        </div>
    )
}

export const AuthBody: React.FC<{ wide?: boolean }> = ({ children, wide }) => {
    return (
        <div
            className={classNames(
                'sm:mt-8 sm:mx-auto sm:w-full ',
                wide ? 'sm:max-w-3xl' : 'sm:max-w-lg'
            )}
        >
            <div className="bg-white pt-7 pb-2 sm:pb-7 px-4 sm:px-7 sm:border sm:border-gray-200 sm:rounded-lg">
                {children}
            </div>
        </div>
    )
}

export const AuthFooter: React.FC<{
    links: {
        href: string
        name: string
    }[]
    label?: string
}> = ({ links, label }) => {
    return (
        <div className="mt-10">
            {label && (
                <h3 className="mb-2 uppercase text-xs font-semibold text-slate-900 tracking-wide">
                    {label}
                </h3>
            )}
            <ul role="list" className="divide-y divide-white/5">
                {links.map((deployment) => (
                    <li
                        key={deployment.href}
                        className="relative flex items-center space-x-4 pt-3"
                    >
                        <div className="min-w-0 flex-auto">
                            <div className="flex items-center gap-x-3">
                                {/*<div className="flex-none rounded-full p-1">*/}
                                {/*    <div className="h-2 w-2 rounded-full bg-current" />*/}
                                {/*</div>*/}
                                <h2 className="min-w-0 text-base font-medium leading-6 text-slate-900">
                                    <Link href={deployment.href} passHref>
                                        <a className="flex gap-x-2">
                                            <span className="truncate">
                                                {deployment.name}
                                            </span>
                                            <span className="absolute inset-0" />
                                        </a>
                                    </Link>
                                </h2>
                            </div>
                        </div>
                        <ChevronRightIcon
                            className="h-5 w-5 flex-none text-gray-500"
                            aria-hidden="true"
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}
